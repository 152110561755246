<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

const emit = defineEmits<{
  confirm: []
  cancel: []
}>()

/* === LISTENERS ===*/
const onConfirm = () => emit('confirm')
const onCancel = () => emit('cancel')
/* === LISTENERS ===*/
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Add to portfolio?"
      close-on-click
      container-class="max-w-[560px] bg-gray-850 border-gray-750 dark:bg-gray-850 dark:border-gray-800"
      @close="onCancel"
    >
      <div class="flex flex-col">
        Not all mandatory fields have been approved. Do you still want to
        proceed and add them to the portfolio anyway?

        <div class="mt-4 flex justify-end gap-2">
          <Button
            type="button"
            color="transparentSecondary"
            class="mt-4 self-end"
            @click="onCancel"
          >
            Cancel
          </Button>

          <Button
            type="button"
            class="ring-primary mt-4 self-end ring-opacity-30 hover:ring"
            @click="onConfirm"
          >
            Confirm
          </Button>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
